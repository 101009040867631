<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Crear servicio</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />
          <v-row>
            <v-col cols="12" md="3"></v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="name"
                :rules="nameRules"
                :counter="190"
                prepend-icon="mdi-octagram"
                label="Nombre *"
              ></v-text-field>
              <v-text-field
                v-model="description"
                :rules="descriptionRules"
                :counter="190"
                prepend-icon="mdi-format-list-group"
                label="Descripción *"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="3"></v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="4">
              <div class="text-right pr-5">

                <v-btn
                  color="primary"
                  outlined
                  class="mr-4"
                  @click="cancel"
                  width="125"
                >
                  <v-icon>mdi-close-circle</v-icon><span> Cancelar</span>
                </v-btn>
                <v-btn color="primary" outlined @click="create" width="125"
                  ><v-icon>mdi-check-circle</v-icon><span>Crear</span>
                </v-btn>
              </div>
            </v-col>
          
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";

export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    name: "",
    description: "",
    menu1: false,
    descriptionRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
    nameRules: [
      (v) =>
        (v && v.length <= 190) || "El campo no debe exceder de 190 caracteres",
    ],
  }),

  methods: {
    cancel() {
      setTimeout(() => this.$router.push({ name: "services" }), 10);
    },

    create() {
      console.log("entra");

      let request = {
        name: this.name,
        description: this.description,
      };
      axios
        .post("/save-service", request)
        .then((response) => {
          this.displayNotification("success", "Éxito", response.data);
          console.log(response.data);
          setTimeout(() => this.$router.push({ name: "services" }), 4000);
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al crear el servicio");
          setTimeout(() => this.$router.push({ name: "services" }), 4000);
        });
    },
  },
};
</script>